// components
import {Card, Select} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// location options
import getLocationOptions from '../../../location/lib/getOptions.lib.location';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// ticket attributes
import driverAttribute from '../../attributes/driver.attribute.ticket';

// ticket lib
import getTicketPriorityLabel from '../../lib/getPriorityLabel.lib.ticket';

// ticketStatus lib
import getTicketStatusOptions from '../../../ticketStatus/lib/getOptions.lib.ticketStatus';

// vehicle components
import VehicleModelSelect from '../../../vehicle/components/VehicleModelSelect/VehicleModelSelect';

// vehicle lib
import showMedallionNumber from '../../../vehicle/lib/showMedallionNumber.lib.vehicle';

const TicketEditModal = ({
  actions,
  checkedin_at,
  dealership,
  dealerships,
  display_priority,
  driver,
  drivers,
  etc,
  externalId,
  externalInvoice,
  images,
  isTicketClosed,
  isVehicleEditable,
  license_plate,
  location,
  locations,
  make,
  medallion,
  model,
  notes,
  onChange,
  onSaveTicket,
  onSaveVehicle,
  owner,
  owners,
  phone_number,
  status,
  tags,
  ticket,
  ticketActions,
  vehicle,
  year,
}) => (
  <Card title={`Ticket #${ticket.id}`}>
    {(Content) => (
      <Content padding="none" noBorder>
        {notes}
        {images}
        {!!ticketActions && <Content padding="none">{ticketActions}</Content>}
        <CompactDataRow
          label={checkedin_at.label.default}
          wrapContent={!ticket.approved}
          noPadding={!ticket.approved}
        >
          {!ticket.approved ? (
            <AttributeInput
              value={checkedin_at}
              onChange={(value) => onSaveTicket(value)()}
              flat
            />
          ) : (
            checkedin_at.display.format()
          )}
        </CompactDataRow>
        <CompactDataRow
          label={etc.label.default}
          wrapContent={!ticket.approved}
          noPadding={!ticket.approved}
        >
          {!ticket.approved ? (
            <AttributeInput
              value={etc}
              onChange={(value) => onSaveTicket(value)()}
              flat
            />
          ) : (
            etc.display.format()
          )}
        </CompactDataRow>
        <CompactDataRow label="Tags">{tags}</CompactDataRow>
        <CompactDataRow
          label={display_priority.label.default}
          wrapContent={!ticket.approved}
          noPadding={!ticket.approved}
        >
          {!ticket.approved ? (
            <AttributeInput
              value={display_priority}
              onChange={(value) => onSaveTicket(value)()}
              flat
            />
          ) : (
            getTicketPriorityLabel({priority: display_priority.api.format()})
          )}
        </CompactDataRow>
        <CompactDataRow
          label={status.label.default}
          wrapContent={!ticket.approved}
          noPadding={!ticket.approved}
        >
          {!ticket.approved ? (
            <AttributeInput
              value={status}
              onChange={(value) => onSaveTicket(value)()}
              options={getTicketStatusOptions()}
              flat
            />
          ) : (
            status.api.format() || '-'
          )}
        </CompactDataRow>
        <CompactDataRow
          label={location.label.default}
          wrapContent={!ticket.approved}
          noPadding={!ticket.approved}
        >
          {!ticket.approved ? (
            <AttributeInput
              value={location}
              onChange={(value) => onSaveTicket(value)()}
              options={getLocationOptions({locations})}
              flat
            />
          ) : (
            location.api.format() || '-'
          )}
        </CompactDataRow>
        {!!drivers.length && (
          <CompactDataRow label="Driver" wrapContent noPadding>
            <Select
              value={`${driver?.id || ''}`}
              onChange={(value) => onSaveTicket(driverAttribute(value))()}
              options={drivers.map(({id, first_name, last_name}) => ({
                value: `${id}`,
                label: `${first_name} ${last_name}`,
              }))}
              disabled={isTicketClosed || ticket.approved}
              placeholder="Select a driver"
              flat
            />
          </CompactDataRow>
        )}
        {!!phone_number && (
          <CompactDataRow
            label={phone_number.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={phone_number}
              onChange={onChange}
              onBlur={onSaveTicket(phone_number)}
              disabled={ticket.approved}
              flat
            />
          </CompactDataRow>
        )}
        {!!dealerships.length && (
          <CompactDataRow
            label={dealership.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={dealership}
              onChange={(value) =>
                onSaveTicket(
                  value.api.format() === 'sally' ? value.reinit('') : value
                )()
              }
              disabled={ticket.approved}
              options={dealerships || []}
              flat
            />
          </CompactDataRow>
        )}
        {actions}
        {externalId}
        {externalInvoice}
        <CompactDataRow label={owner.label.default} wrapContent noPadding>
          <AttributeInput
            value={owner}
            onChange={(value) => onSaveVehicle(value)()}
            options={owners || []}
            disabled={!isVehicleEditable || ticket.approved}
            flat
          />
        </CompactDataRow>
        <CompactDataRow label="VIN">{vehicle.vin || '-'}</CompactDataRow>
        {!!vehicle.svid && (
          <CompactDataRow label="SVID">{vehicle.svid || '-'}</CompactDataRow>
        )}
        <CompactDataRow
          label={license_plate.label.default}
          wrapContent
          noPadding
        >
          <AttributeInput
            value={license_plate}
            onChange={onChange}
            onBlur={onSaveVehicle(license_plate)}
            disabled={!isVehicleEditable || ticket.approved}
            flat
          />
        </CompactDataRow>
        {showMedallionNumber(vehicle) && (
          <CompactDataRow label={medallion.label.default} wrapContent noPadding>
            <AttributeInput
              value={medallion}
              onChange={onChange}
              onBlur={onSaveVehicle(medallion)}
              disabled={!isVehicleEditable || ticket.approved}
              flat
            />
          </CompactDataRow>
        )}
        <CompactDataRow label={year.label.default} wrapContent noPadding>
          <AttributeInput
            value={year}
            onChange={(value) => onSaveVehicle(value)()}
            disabled={!isVehicleEditable || ticket.approved}
            flat
          />
        </CompactDataRow>
        <CompactDataRow label={make.label.default} wrapContent noPadding>
          <AttributeInput
            value={make}
            onChange={onChange}
            onBlur={onSaveVehicle(make)}
            disabled={!isVehicleEditable || ticket.approved}
            flat
          />
        </CompactDataRow>
        <CompactDataRow label="Model" wrapContent noPadding>
          <VehicleModelSelect
            model={model}
            onChange={(value) => onSaveVehicle(value)()}
            disabled={!isVehicleEditable || ticket.approved}
            flat
          />
        </CompactDataRow>
      </Content>
    )}
  </Card>
);

TicketEditModal.propTypes = {
  actions: PropTypes.node,
  checkedin_at: PropTypes.object,
  dealership: PropTypes.object,
  dealerships: PropTypes.array,
  display_priority: PropTypes.object,
  driver: PropTypes.object,
  drivers: PropTypes.array,
  etc: PropTypes.object,
  externalId: PropTypes.node,
  externalInvoice: PropTypes.node,
  images: PropTypes.node,
  isTicketClosed: PropTypes.bool,
  isVehicleEditable: PropTypes.bool,
  license_plate: PropTypes.object,
  location: PropTypes.object,
  locations: PropTypes.array,
  make: PropTypes.object,
  medallion: PropTypes.object,
  model: PropTypes.object,
  notes: PropTypes.node,
  onChange: PropTypes.func,
  onSaveTicket: PropTypes.func,
  onSaveVehicle: PropTypes.func,
  owner: PropTypes.object,
  owners: PropTypes.array,
  phone_number: PropTypes.object,
  status: PropTypes.object,
  tags: PropTypes.node,
  ticket: PropTypes.object,
  ticketActions: PropTypes.node,
  vehicle: PropTypes.object,
  year: PropTypes.object,
};

export default TicketEditModal;

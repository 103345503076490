import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {
  set as setAct,
  syncServices as syncServicesAct,
  removeService as removeServiceAct,
  removeUnusedServices as removeUnusedServicesAct,
} from '../../../spotlight/redux/actions';

// Api
import listServicesApi from '../../api/list.api.service';

// Database
// import DB_FIELDS from '../../database/fields.database.service';
import servicesModel from '../../database/model.database.service';

// Events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// Sockets
import serviceCreatedSocket from '../../sockets/created.socket.service';
import serviceDeletedSocket from '../../sockets/deleted.socket.service';
import serviceUpdatedSocket from '../../sockets/updated.socket.service';

class ServicesSpotlightSync extends Component {
  static propTypes = {
    services: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(
      serviceCreatedSocket.subscribe(this.updateService),
      serviceDeletedSocket.subscribe(this.deleteService),
      serviceUpdatedSocket.subscribe(this.updateService)
    );
  }

  init = async () => {
    const {dispatch} = this.props;
    const services = await this.getStoredServices();
    dispatch(syncServicesAct(services, {storeToDb: false}));
    dispatch(setAct({servicesLoading: true}));
    const freshServices = await this.fetch();
    dispatch(removeUnusedServicesAct(freshServices));
    dispatch(setAct({servicesLoading: false, servicesLoaded: true}));
  };

  updateService = (service) => {
    if (!this.props.servicesLoaded) return;
    this.props.dispatch(syncServicesAct([service], {storeToDb: false}));
  };

  deleteService = ({id}) => {
    if (!this.props.servicesLoaded) return;
    this.props.dispatch(removeServiceAct(id));
  };

  getStoredServices = async () => {
    try {
      const services = await servicesModel().toArray();
      return services;
    } catch (error) {
      return [];
    }
  };

  fetch = async ({services = [], limit = 100, offset = 0} = {}) => {
    try {
      const {results, next} = await listServicesApi({
        limit,
        offset,
        // fields: DB_FIELDS.join(','),
      });
      const newList = [...services, ...results];
      this.props.dispatch(syncServicesAct(results, {storeToDb: false}));
      if (!!next)
        return this.fetch({
          services: newList,
          limit,
          offset: offset + limit,
        });
      return newList;
    } catch (error) {
      return services;
    }
  };

  render() {
    return null;
  }
}

export default connect((state) => ({services: state.spotlight.services}))(
  subscriptionHoc(ServicesSpotlightSync)
);

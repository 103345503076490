// alertify
import alertify from '../../../layout/lib/alertify';

// dealership lib
import getBranchDealershipOptions from '../../../dealership/lib/getBranchDealershipOptions.lib.dealership';
// import getDealership from '../../../dealership/lib/getDealership.lib.dealership';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// owner events
import ownerCreatedEvent from '../../../owner/events/created.event.owner';

// owner prep
import prepareOwnersForSelect from '../../../owner/lib/prepareForSelect.lib.owner';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// redux
import {connect} from 'react-redux';

// ticket attributes
import checkedinAtAttr from '../../attributes/checkedin_at.attribute.ticket';
import dealershipAttr from '../../attributes/dealership.attribute.ticket';
import displayPriorityAttr from '../../attributes/display_priority.attribute.ticket';
import etcAttr from '../../attributes/etc.attribute.ticket';
import locationAttr from '../../attributes/location.attribute.ticket';
import phoneNumberAttr from '../../attributes/phone_number.attribute.ticket';
import statusAttr from '../../attributes/status.attribute.ticket';

// ticket api
import updateTicketApi from '../../api/update.api.ticket';

// ticket components
// import TicketEditCard from '../../components/TicketEditCard/TicketEditCard';
import TicketEditModal from '../../components/TicketEditModal/TicketEditModal';

// ticket lib
import isTicketEditable from '../../lib/isEditable.lib.ticket';

// ticket permissions
import updateTicketPermission from '../../../ticket/permissions/update.permission.ticket';

// vehicle api
import updateVehicleApi from '../../../vehicle/api/update.api.vehicle';

// vehicle attributes
import licensePlateAttr from '../../../vehicle/attributes/license_plate.attribute.vehicle';
import makeAttr from '../../../vehicle/attributes/make.attribute.vehicle';
import medallionAttr from '../../../vehicle/attributes/medallion.attribute.vehicle';
import modelAttr from '../../../vehicle/attributes/model.attribute.vehicle';
import ownerAttr from '../../../vehicle/attributes/owner.attribute.vehicle';
import yearAttr from '../../../vehicle/attributes/year.attribute.vehicle';

// vehicle lib
import isVehicleEditable from '../../../vehicle/lib/isEditable.lib.vehicle';

// vehicle preparations
import generalVehiclePrep from '../../../vehicle/preparations/general.preparation.vehicle';

class TicketEditContainer extends Component {
  static propTypes = {
    actions: PropTypes.node,
    drivers: PropTypes.array,
    externalId: PropTypes.node,
    externalInvoice: PropTypes.node,
    images: PropTypes.node,
    locations: PropTypes.array,
    notes: PropTypes.node,
    owners: PropTypes.array,
    syncTicket: PropTypes.func,
    tags: PropTypes.node,
    ticket: PropTypes.object,
    ticketActions: PropTypes.node,
  };

  state = {
    checkedin_at: checkedinAtAttr(''),
    dealership: dealershipAttr(''),
    display_priority: displayPriorityAttr(''),
    editModalVisible: false,
    etc: etcAttr(''),
    license_plate: licensePlateAttr(''),
    location: locationAttr(''),
    make: makeAttr(''),
    medallion: medallionAttr(''),
    model: modelAttr(''),
    owner: ownerAttr(''),
    phone_number: phoneNumberAttr(''),
    status: statusAttr(''),
    year: yearAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(ownerCreatedEvent.subscribe(this.ownerCreated));
    this.showEditModal();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  ownerCreated = (owner) => {
    this.setState(
      {vehicle: {...this.state.vehicle, owner: ownerAttr(`${owner.id}`)}},
      () => {
        this.saveVehicleInfo('owner')();
      }
    );
  };

  change = (value, key) => this.setState({[key]: value});

  saveTicket = (attribute) => async () => {
    const {ticket, drivers, syncTicket} = this.props;

    if (!updateTicketPermission()) return;
    this.setState({[attribute.attribute]: attribute});

    try {
      const savedTicket = await updateTicketApi(ticket.id, {
        [attribute.attribute]: attribute.api.format(),
      });
      const updatedTicket = {
        ...ticket,
        checkedin_at: savedTicket.checkedin_at,
        dealership: savedTicket.dealership,
        display_priority: savedTicket.display_priority,
        external_invoice_id: savedTicket.external_invoice_id,
        phone_number: savedTicket.phone_number,
      };
      if (attribute.attribute === 'driver')
        updatedTicket.driver = [...drivers].find(
          ({id}) => id === savedTicket.driver
        );
      syncTicket(updatedTicket);
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
    }
  };

  saveVehicle = (attribute) => async () => {
    const {ticket, syncTicket} = this.props;

    if (!updateTicketPermission() || !isTicketEditable(ticket)) return;
    this.setState({[attribute.attribute]: attribute});

    try {
      const preppedVehicleAttribute = await generalVehiclePrep({
        attributes: [attribute],
        required: [],
      });

      const preparedVehicleAttribute = !Object.keys(preppedVehicleAttribute)
        .length
        ? {[attribute.attribute]: ''}
        : preppedVehicleAttribute;

      const newVehicle = await updateVehicleApi(
        ticket.vehicle.id,
        preparedVehicleAttribute
      );
      if (!this.mounted) return;
      const newTicket = {...ticket, vehicle: newVehicle};
      syncTicket(newTicket);
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
    }
  };

  showEditModal = () => {
    const {ticket} = this.props;
    if (!isTicketEditable(ticket) || !updateTicketPermission()) return;
    this.setState({
      checkedin_at: checkedinAtAttr(`${ticket?.checkedin_at || ''}`),
      dealership: dealershipAttr(`${ticket?.dealership || ''}`),
      display_priority: displayPriorityAttr(
        `${ticket?.display_priority || '0'}`
      ),
      editModalVisible: true,
      etc: etcAttr(`${ticket?.etc || ''}`),
      license_plate: licensePlateAttr(ticket?.vehicle.license_plate || ''),
      location: locationAttr(
        `${ticket?.location?.id || ticket?.location || ''}`
      ),
      make: makeAttr(ticket?.vehicle.make || ''),
      medallion: medallionAttr(ticket?.vehicle.medallion || ''),
      model: modelAttr(ticket?.vehicle.model || ''),
      owner: ownerAttr(
        !!ticket?.vehicle.owner ? `${ticket?.vehicle.owner}` : ''
      ),
      phone_number: phoneNumberAttr(this.getPhoneNumber()),
      status: statusAttr(`${ticket?.status?.id || ticket?.status || ''}`),
      year: yearAttr(ticket?.vehicle.year || ''),
    });
  };

  getPhoneNumber = () => {
    const {ticket} = this.props;
    if (!isTicketEditable(ticket) || !updateTicketPermission()) return;
    return ticket?.phone_number || ticket?.driver?.phone_number || '';
  };

  hideEditModal = () => {
    this.setState({editModalVisible: false});
  };

  getDealerships = () => {
    const {ticket} = this.props;
    return getBranchDealershipOptions({
      branch: ticket?.vehicle?.branch,
      withEmpty: !!ticket.dealership,
    });
  };

  render() {
    const {
      actions,
      drivers,
      externalId,
      externalInvoice,
      images,
      locations,
      notes,
      owners,
      tags,
      ticket,
      ticketActions,
    } = this.props;
    const {
      checkedin_at,
      dealership,
      display_priority,
      editModalVisible,
      etc,
      license_plate,
      location,
      make,
      medallion,
      model,
      owner,
      phone_number,
      status,
      year,
    } = this.state;
    return (
      <Fragment>
        {/* <TicketEditCard
          dealership={getDealership({dealership: ticket.dealership})}
          onClick={this.showEditModal}
          phoneNumber={this.getPhoneNumber()}
          showDriver={!!drivers.length}
          ticket={ticket}
        /> */}
        <TicketEditModal
          actions={actions}
          checkedin_at={checkedin_at}
          dealership={dealership}
          dealerships={this.getDealerships()}
          display_priority={display_priority}
          driver={ticket.driver}
          drivers={drivers}
          etc={etc}
          externalId={externalId}
          externalInvoice={externalInvoice}
          images={images}
          isTicketClosed={ticket.approved}
          isVehicleEditable={
            isVehicleEditable(ticket?.vehicle) && !ticket?.vehicle_fields
          }
          license_plate={license_plate}
          location={location}
          locations={locations}
          make={make}
          medallion={medallion}
          model={model}
          notes={notes}
          onChange={this.change}
          onClose={this.hideEditModal}
          onSaveTicket={this.saveTicket}
          onSaveVehicle={this.saveVehicle}
          owner={owner}
          owners={prepareOwnersForSelect({owners, enableCustom: false})}
          phone_number={phone_number}
          status={status}
          tags={tags}
          ticket={ticket}
          ticketActions={ticketActions}
          vehicle={ticket.vehicle}
          visible={editModalVisible}
          year={year}
        />
      </Fragment>
    );
  }
}

export default subscriptionHOC(
  connect((state) => ({owners: state.owner.owners}))(TicketEditContainer)
);

const columns = [
  {
    key: 'id',
    label: 'Ticket',
    span: 1 / 3,
    sortable: true,
  },
  {
    key: 'checkedin_at',
    label: 'Check In',
    span: 1 / 2,
    sortable: true,
  },
  {
    key: 'etc',
    label: 'ETC',
    span: 1 / 2,
    sortable: true,
  },
  {
    key: 'vehicle__branch__tag',
    label: 'Branch',
    span: 1 / 2,
    sortable: true,
  },
  {
    key: 'owner',
    label: 'Owner',
    span: 1 / 2,
  },
  {
    key: 'mechanic',
    label: 'Mechanic',
    span: 1 / 2,
  },
  {
    key: 'state',
    label: 'State',
    span: 1 / 2,
  },
  {
    key: 'vehicle',
    label: 'Vehicle',
  },
  // {
  //   key: 'vehicle',
  //   label: 'Plate',
  //   span: 1 / 2,
  // },
  // {
  //   key: 'svid',
  //   label: 'SVID',
  //   span: 1 / 2,
  // },
  // {
  //   key: 'model',
  //   label: 'Model',
  // },
  {
    key: 'display_priority',
    label: 'Priority',
    span: 1 / 2,
    sortable: true,
  },
  {
    key: 'tags',
    label: 'Tags',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'lastNote',
    label: 'Latest Note',
  },
];

export default columns;

// branch lib
import getBranchName from '../../../branch/lib/getBranchName.lib.branch';

// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// mechanic lib
import getMechanicName from '../../../mechanic/lib/getName.lib.mechanic';

// owner lib
import getOwnerName from '../../../owner/lib/getName.lib.owner';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// vehicle components
import VehicleModelInfo from '../../../vehicle/components/VehicleModelInfo/VehicleModelInfo';

// vehicle lib
import displayVehicleFieldFromObject from '../../../vehicle/lib/displayVehicleFieldFromObject.lib.vehicle';
import vehicleOrVehicleFields from '../../../vehicle/lib/vehicleOrVehicleFields.lib.vehicle';

// tag containers
import TicketTagEditorContainer from '../../../tag/containers/TicketTagEditorContainer/TicketTagEditorContainer';

// ticket lib
import getTicketEtc from '../../lib/getEtc.lib.ticket';
import getTicketPriorityLabel from '../../../ticket/lib/getPriorityLabel.lib.ticket';

// ticketNote lib
import getLatestTicketNote from '../../../ticketNote/lib/getLatestNote.lib.ticketNote';

// types
import dateType from '@matthahn/sally-fw/lib/type/types/date.type';

const TicketsList = ({
  actions = [],
  columns,
  filters = [],
  loading,
  mechanics,
  onPage,
  onSearch,
  onSort,
  onTicket,
  page,
  pages,
  search,
  sort,
  tickets,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    filters={filters}
    onSearch={onSearch}
    onSort={onSort}
    search={search}
    searchPlaceholder="Search Tickets"
    sort={sort}
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      tickets.map((ticket) => (
        <TableRow key={ticket.id}>
          {(TableCol) => [
            <TableCol onClick={onTicket(ticket)} key="id" span={1 / 3}>
              {ticket.id}
            </TableCol>,
            <TableCol onClick={onTicket(ticket)} key="date" span={1 / 2}>
              {[
                ticket.date || ticket.checkedin_at,
                !!ticket?.approved && ticket.approved_at,
              ]
                .filter((date) => !!date)
                .map((date) => dateType(date).format())
                .join(' - ')}
            </TableCol>,
            <TableCol onClick={onTicket(ticket)} key="etc" span={1 / 2}>
              {!!getTicketEtc(ticket)
                ? dateType(getTicketEtc(ticket)).format()
                : '-'}
            </TableCol>,
            <TableCol onClick={onTicket(ticket)} key="branch" span={1 / 2}>
              {getBranchName(ticket?.vehicle?.branch || ticket?.branch) || '-'}
            </TableCol>,
            <TableCol onClick={onTicket(ticket)} key="owner" span={1 / 2}>
              {getOwnerName({ownerId: ticket?.vehicle?.owner})}
            </TableCol>,
            <TableCol onClick={onTicket(ticket)} key="mechanic" span={1 / 2}>
              {getMechanicName({mechanicId: ticket.mechanic, mechanics})}
            </TableCol>,
            <TableCol onClick={onTicket(ticket)} key="state" span={1 / 2}>
              {ticket?.vehicle?.george_vehicle_state || '-'}
            </TableCol>,
            <TableCol onClick={onTicket(ticket)} key="vehicle">
              <div>
                {displayVehicleFieldFromObject({
                  object: {
                    vehicle: ticket.vehicle,
                    vehicle_fields: ticket.vehicle_fields,
                  },
                  field: 'license_plate',
                  empty: 'Unknown plate',
                })}
              </div>
              <div>
                {displayVehicleFieldFromObject({
                  object: {
                    vehicle: ticket.vehicle,
                    vehicle_fields: ticket.vehicle_fields,
                  },
                  field: 'svid',
                  empty: '-',
                })}
              </div>
              <div>
                <VehicleModelInfo
                  {...vehicleOrVehicleFields({
                    vehicle: ticket.vehicle,
                    vehicle_fields: ticket.vehicle_fields,
                  })}
                  withoutMake
                />
              </div>
            </TableCol>,
            <TableCol key="priority" span={1 / 2}>
              {getTicketPriorityLabel({priority: ticket?.display_priority})}
            </TableCol>,
            <TableCol key="tags">
              <TicketTagEditorContainer ticket={ticket} small />
            </TableCol>,
            <TableCol onClick={onTicket(ticket)} key="description">
              {ticket.notes || '-'}
            </TableCol>,
            <TableCol onClick={onTicket(ticket)} key="lastNote">
              {getLatestTicketNote(ticket)}
            </TableCol>,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

TicketsList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  mechanics: PropTypes.array,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  onTicket: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  sort: PropTypes.object,
  tickets: PropTypes.array,
};

export default TicketsList;
